import React, { useState, useEffect, useCallback } from "react";
import Header from "./components/Header";
import { fetchDefiProtocols } from "./services/defiService";

function App() {
  const [filter, setFilter] = useState("");
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);

  const loadProjects = useCallback(async (page = 1, newSearch = false) => {
    try {
      if (page === 1) {
        setLoading(true);
      }
      const data = await fetchDefiProtocols(page, filter);
      if (newSearch || page === 1) {
        setProjects(data.protocols);
      } else {
        setProjects(prev => [...prev, ...data.protocols]);
      }
      setPagination(data.pagination);
      setCurrentPage(page);
    } catch (err) {
      setError('Failed to load DeFi projects');
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  }, [filter]);

  // Initial load
  useEffect(() => {
    loadProjects(1, true);
  }, [loadProjects]);

  // Handle filter changes with debouncing
  useEffect(() => {
    const timer = setTimeout(() => {
      loadProjects(1, true);
    }, 300);

    return () => clearTimeout(timer);
  }, [filter, loadProjects]);

  const loadMoreProjects = async () => {
    if (loadingMore || !pagination?.hasMore) return;

    try {
      setLoadingMore(true);
      const nextPage = currentPage + 1;
      await loadProjects(nextPage);
    } catch (err) {
      setError('Failed to load more projects');
    }
  };

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(filter.toLowerCase())
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header filter={filter} setFilter={setFilter} />
        <div className="container mx-auto px-4 py-8 flex justify-center items-center">
          <div className="text-gray-600">Loading DeFi projects...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header filter={filter} setFilter={setFilter} />
        <div className="container mx-auto px-4 py-8 flex justify-center items-center">
          <div className="text-red-600">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header filter={filter} setFilter={setFilter} />
      
      <main className="container mx-auto px-4 py-8">
        <h1 className="sr-only">DeFi Protocol Analytics & TVL Tracking</h1>
        
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" aria-label="DeFi Protocols">
          {filteredProjects.map((project) => (
            <article
              key={project.id}
              className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-200"
            >
              <div className="flex items-center space-x-4">
                <img
                  src={project.logo}
                  alt={`${project.name} logo`}
                  className="w-12 h-12 rounded-full"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "https://via.placeholder.com/48";
                  }}
                  loading="lazy"
                />
                <div>
                  <h2 className="text-xl font-semibold text-gray-900">
                    {project.name}
                  </h2>
                  <p className="text-sm text-gray-500">{project.category}</p>
                </div>
              </div>
              
              <div className="mt-4">
                <dl className="flex justify-between items-center text-sm text-gray-600">
                  <div>
                    <dt className="sr-only">Blockchain</dt>
                    <dd>Chain: {project.blockchain}</dd>
                  </div>
                  <div>
                    <dt className="sr-only">Total Value Locked</dt>
                    <dd>TVL: ${(project.tvl / 1e6).toFixed(2)}M</dd>
                  </div>
                </dl>
                <a
                  href={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 block text-center text-blue-600 hover:text-blue-800 font-medium"
                  aria-label={`View ${project.name} project details`}
                >
                  View Project →
                </a>
              </div>
            </article>
          ))}
        </section>
        
        {loadingMore && (
          <div className="mt-8 text-center text-gray-600" role="status" aria-live="polite">
            Loading more projects...
          </div>
        )}
        
        {!loadingMore && pagination?.hasMore && !filter && (
          <div className="mt-8 text-center">
            <button
              onClick={loadMoreProjects}
              disabled={loadingMore}
              className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              aria-label={loadingMore ? 'Loading more projects...' : 'Load more projects'}
            >
              {loadingMore ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
        
        {pagination && (
          <div className="mt-4 text-center text-sm text-gray-600" role="status" aria-live="polite">
            Showing {filteredProjects.length} of {pagination.totalItems} projects
          </div>
        )}
      </main>
    </div>
  );
}

export default App;